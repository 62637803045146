import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { Col, Row, Alert, Form, FormGroup, Label, Input } from 'reactstrap';
import { loginUserAction } from '../../actions/userAction'
import ButtonLoading from '../../components/ButtonLoading/'
import LogRocket from 'logrocket';

class Login extends Component {
    state = {
        username: "",
        password: "",
        avatar: "",
        bio: ""
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.loginUser(this.state)
    }

    render() {
        const { isAuthenticated, isPending, error } = this.props;
        return (
            <>
                {
                    isAuthenticated ? (<Redirect to="/" ></Redirect>) : (<Fragment>
                        <div className="h-100 bg-login bg-animation">
                            <div className="d-flex h-100 justify-content-center align-items-center">
                                <Col md="8" className="mx-auto app-login-box">

                                    <div className="modal-dialog w-100 mx-auto">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <div className="h5 modal-title text-center">
                                                    <h4 className="mt-2 mb-3">
                                                        <div>Benvenuto su Aziendasicura,</div>
                                                        <span>inserisci email e password per accedere.</span>
                                                    </h4>
                                                    {error &&
                                                        <Alert className="my-3 justify-content-around" color="danger">
                                                            {error.message}
                                                        </Alert>
                                                    }
                                                </div>
                                                <Form>

                                                    <Row form>
                                                        <Col md={12} >
                                                            <FormGroup className="justify-content-around">
                                                                <Input name='username'
                                                                    placeholder='Username'
                                                                    value={this.state.username}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className="justify-content-around">
                                                                <Input type='password'
                                                                    name='password'
                                                                    placeholder='Password'
                                                                    value={this.state.password}
                                                                    onChange={this.handleChange} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <FormGroup check>
                                                        <Input type="checkbox" name="check" id="exampleCheck" />
                                                        <Label for="exampleCheck" check>Keep me logged in</Label>
                                                    </FormGroup> */}
                                                </Form>
                                            </div>
                                            <div className="modal-footer clearfix">
                                                <div className="float-left">
                                                    <a href="#/" className="btn-lg btn btn-link">Recover
                                                    Password</a>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonLoading onClick={this.handleSubmit} loading={isPending} color="primary">Login to Dashboard</ButtonLoading>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center text-white opacity-8 mt-3">
                                        Copyright &copy; Trame Digitali 2019
                                        </div>
                                </Col>
                            </div>
                        </div>
                    </Fragment>)
                }</>
        )



    }
}

const mapDispatchToProps = dispatch => ({
    loginUser: userInfo => dispatch(loginUserAction(userInfo))
})

const mapStateToProps = state => {

    const { user } = state.user;
    const isPending = state.user.pending;
    const error = state.user.error;
    const isAuthenticated = state.user.user && Object.entries(state.user.user).length !== 0;

    if (isAuthenticated) {
        LogRocket.identify(state.user.user.username);
    }
    return {
        isAuthenticated,
        user,
        isPending,
        error
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
