import axios from 'axios'

// fuccillo
// let baseUrl = 'https://api-fuccillo.digitra.me'

// // canesi
let baseUrl = 'https://api-canesi.digitra.me'

// // colzi
// let baseUrl = 'http://localhost:8000'

// if (process.env.NODE_ENV === 'production') {
//     baseUrl = 'https://colzi-ws.herokuapp.com/'
// }


export default axios.create({
    baseURL: baseUrl,
    timeout: 1000,
    headers: { 'X-Man-Token': 'foobar' }
});