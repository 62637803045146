import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardFooter, Button, FormGroup } from 'reactstrap'
import FormJson from "react-jsonschema-form";
import Loader from 'react-loaders'
import cx from 'classnames'
import { TiTickOutline, TiTimesOutline } from 'react-icons/ti'

const FormInfo = ({ heading, schema, formData, onChange, onSubmit, onError, children, pending, isChildrenInside, messages }) => (
    <Card className="main-card mb-3 px-5">
        <FormGroup>


            <CardBody className="pt-3 c-body-no-head d-flex justify-content-center  flex-column">
                {heading && <h3 className="mb-4">{heading}</h3>}
                {messages &&
                    <div className={cx("message my-3 shadow", {
                        success: messages.status,
                        error: !messages.status,
                    })}
                    >
                        {messages.status ? <TiTickOutline className="icons" /> : <TiTimesOutline className="icons" />}
                        <span className="text">{messages.message}</span>
                    </div>
                }
                {pending ?
                    (
                        <>
                            <div className="align-self-center pt-5 d-flex align-items-center flex-column">
                                <h4>Solo un attimo...</h4>
                                <Loader type="ball-pulse" />
                            </div>
                        </>
                    )
                    : (
                        <FormJson schema={schema}
                            className="form-document"
                            formData={formData}
                            onChange={onChange}
                            onError={onError}>
                            {isChildrenInside && children}
                        </FormJson>
                    )
                }
                {!isChildrenInside && children}
            </CardBody>
        </FormGroup>
        <CardFooter className="justify-content-end">
            <Button className="btn-shadow" color="secondary">
                Cancella
            </Button>
            <Button onClick={onSubmit} className="ml-3 btn-shadow" color="primary">
                Salva modifiche
            </Button>
        </CardFooter>
    </Card >
)

FormInfo.propTypes = {
    schema: PropTypes.object.isRequired,
    formData: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
}

FormInfo.defaultProps = {

}

export default FormInfo