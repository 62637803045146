import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import Api from '../../../api/api'
import {
    PageTitle,
    ErrorCard,
    FormInfo
} from '../../../components/'
import Select from 'react-select'
import {
    Button, Row,
    Col, Container,
    FormGroup, Label
} from 'reactstrap';
import { FaPlus } from 'react-icons/fa'
import { addCompanyAction, cleanUpNewCompanyAction } from '../../../actions/companyAction'
import { labelMap, schema } from '../../../utils/forms/company'
import { parseForOptions } from '../../../utils/select/transformIntoOption'
import { confirmImg } from '../../../assets/images/';

// const RapidAction = company => (
//     <Col md="4" className="pr-5 d-flex flex-column justify-content-start align-items-end">
//         <h4 className="mt-2 mb-3 mr-2 text-light">
//             Azioni rapide
//                                         </h4>
//         <Link to={{ pathname: '/document/add/', state: { companyId: company.id } }}>
//             <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
//                 <div className="mr-3">
//                     <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
//                 </div>
//                 <div className="text-black">
//                     Inserisci nuova scadenza
//                 </div>
//             </Button>
//         </Link>
//         <Link to="/company/id/">
//             <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-4 d-flex text-left align-items-center">
//                 <div className="mr-3">
//                     <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
//                 </div>
//                 <div className="text-black">
//                     Aggiungi un dipendente
//                 </div>
//             </Button>
//         </Link>

//     </Col>
// )

class CompanyAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shorthand: '',
            business_name: '',
            description: '',
            sector_id: '',
            vat_number: "",
            address: '',
            expiries: 0,
            createdAt: '',
            sectors: [],
            select: {
                sector_id: {}
            }
        };
        this.initialState = this.state
    }

    handleChange = evt => {
        this.setState(prevState => ({
            ...prevState,
            ...evt.formData,

        }), () => console.log("company/add: updating state", this.state))
    }

    loadSectors = async () => {
        const sectors = await Api.getSectors()
        return sectors
    }

    submitForm = evt => {
        this.props.addCompany(this.state)
    }

    resetState = () => {
        this.props.cleanUpNewCompany()
        const loadedSectors = this.state.sectors
        this.setState(prevState => ({ ...this.initialState, sectors: loadedSectors, }), () => console.log(this.state))
    }

    handleSelect = (evt, metadata) => {
        const valueForSelect = evt;
        const value = evt.value;
        const name = metadata.name;
        console.log(valueForSelect, value, name)
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            select: {
                ...prevState.select,
                [name]: valueForSelect
            }
        }), () => console.log(this.state))
    }

    async componentDidMount() {
        const sectors = await this.loadSectors()
        const sectorsOption = parseForOptions(sectors.data, 'id', 'name', 'description')
        this.setState({ sectors: sectorsOption })
    }

    componentWillUnmount() {
        this.props.cleanUpNewCompany()
    }

    render() {
        delete schema.properties.id;
        delete schema.properties.created_at;

        const { isPending, created } = this.props;

        return (
            <Container fluid>
                <PageTitle
                    heading="Aggiungi una nuova azienda"
                    subheading="Qui puoi creare una nuova azienda da inserire nel sistema."
                    icon={false}
                >
                    {/* <RapidAction></RapidAction> */}
                </PageTitle>
                <Row className="my-3 pt-3">
                    <Col md="5">
                        <FormInfo
                            heading={"Inserisci i dati anagrafici"}
                            pending={isPending}
                            schema={schema}
                            onChange={e => this.handleChange(e)}
                            onSubmit={e => this.submitForm(e)}
                            onError={e => console.log(e)}
                            formData={this.state}
                            messages={created.status !== null && {
                                status: created.status,
                                message: created.message
                            }}
                            isChildrenInside={true}
                        >
                            <FormGroup>
                                <Label>
                                    Settore
                                </Label>
                                <Select
                                    isLoading={this.state.sectors.length === 0}
                                    name="sector_id"
                                    options={this.state.sectors}
                                    onChange={this.handleSelect}
                                    value={this.state.select.sector_id}
                                />
                            </FormGroup>
                        </FormInfo>
                    </Col>
                    {created.status && <Col md="6" className="d-flex flex-column justify-content-center align-items-center">
                        <h3 className="">Inserita con successo!</h3>
                        <h6 className="lead mb-4">Abbiamo aggiunto l'azienda {created.company[0].business_name} al sistema</h6>
                        <img width="30%" src={confirmImg}></img>
                        <Row className="justify-content-center mt-4">
                            <Col md={12}>
                                <Button className="btn-shadow mr-4 bigger" onClick={this.resetState} color="info">Inserisci un'altra azienda</Button>
                                <Link to={`/company/edit/${created.company[0].id}`}>
                                    <Button className="btn-shadow bigger" color="primary">Vai all'azienda</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>}

                </Row>
            </Container >
        )
    }
}

const mapDispatchToProps = dispatch => ({
    addCompany: company => dispatch(addCompanyAction(company)),
    cleanUpNewCompany: () => dispatch(cleanUpNewCompanyAction())
})

const mapStateToProps = state => {
    const isPending = state.companies.pending;
    const created = state.companies.created;
    return { isPending, created }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyAdd))